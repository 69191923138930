import "./styles.css";
import ImageCard from "./ImageCard";

const email = "moutaztarbin@gmail.com";
export default function App() {
  return (
    <div className="App">
      <div className="header">
        <div className="logo">DR</div>
      </div>
      <div className="spacer" />
      <section className="tagline">
        <h1 className="serif">
          <i>For the life of your project and the project of your life.</i>
        </h1>
        <p className="grey">
          <i>
            We've reimagined the construction experience to make your dreams a
            reality
          </i>
        </p>
      </section>
      <img
        className="hero"
        src="https://images.unsplash.com/photo-1524190952534-79b1f7d6ad5c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2148&q=80"
      />
      <a href={`mailto:${email}`}>Get started</a>
      <section className="steps">
        <div>
          <h1>How it Works</h1>
          <p>
            We offer comprehensive construction management, planning, and
            execution services for all industries, including commercial,
            industrial, and residential.
          </p>
        </div>

        <div>
          <h2>01 All-in-One Estimate</h2>
          <p>
            No more hunting around for quotes or trying to understand complex
            pricing structures. Sit back and relax while our experts scope out
            every detail of your project and deliver the best market prices, all
            at no cost to you.
          </p>
        </div>
        <div>
          <h2>02 The Design of your dreams</h2>
          <p>
            Collaborate with a dedicated team of industry-leading designers to
            help you bring your vision to life. We'll work with you every step
            of the way to ensure that the end result is exactly what you had in
            mind.
          </p>
        </div>
        <div>
          <h2>03 Make your Dream a Reality</h2>
          <p>
            You won't have to wait long to see your vision become a reality. Our
            team of skilled professionals will quickly turn your dreams into a
            finished product that you'll love.
          </p>
        </div>
      </section>
      <div className="spacer" />
      <h1>Projects</h1>
      <div className="images">
        <ImageCard imgSrc="https://i.imgur.com/N52zAaF.png" title="Tower">
          Beautiful residential tower, featuring an observatory, live
          architecture, and beautiful glass-detailed rounded balconies.
        </ImageCard>
        <ImageCard imgSrc="https://i.imgur.com/4nnOAg3.png" title="Hotel">
          Modern architecture, with multiple room designs, live architecture,
          and beautiful, indoor gym & pool, and elegant corner suits.
        </ImageCard>
      </div>
      <section className="end">
        <a href={`mailto:${email}`}>Make your dreams a reality</a>
        <h1>Dominion Renovation</h1>
        <div>
          <p>{email}</p>
          <p>2515087103</p>
        </div>
      </section>
    </div>
  );
}
