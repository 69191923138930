const ImageCard = ({ imgSrc, title, children }) => {
  return (
    <div className="imageCard">
      <img src={imgSrc} />
      <p className="title">{title}</p>
      <p>{children}</p>
    </div>
  );
};

export default ImageCard;
